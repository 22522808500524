import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController } from '@ionic/angular';
import { ApiService } from './api.service';
import { CommonService } from './common.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  loading: any;
  translationData = {};
  // if localStorage doesnt have translation data then get from API otherwise use localstorage translation by passing locale in localstorage or default 1043
  constructor(public loadingController: LoadingController, public httpClient: HttpClient, private translate: TranslateService, private _apiService: ApiService, public _commonService: CommonService, private router: Router) {
    if (!localStorage.translations && this._commonService.isLoggedIn) {
      this.getTranslation(((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043));
      this._commonService.locale = ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043);
      localStorage.locale = ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043);
    }
    this.getLocalTranslation(localStorage.locale);
  }

  /*
    if locale in localstorage is null or undefined set default locale to 1043
    Get translation according to locale and set that translation
  */
  getLocalTranslation(locale) {
    if (!localStorage.translations || localStorage.translations == "undefined") {
      if (!localStorage.locale || localStorage.locale == "null" || localStorage.locale == "undefined") localStorage.locale = 1043;
      if (localStorage.locale == '1043') {
        this.translationData = {
          "label-userName": "Gebruikersnaam",
          "label-chat": "Chat",
          "label-knowledgeCenter": "Kenniscentrum",
          "label-name": "Name",
          "label-thanksForRegistration": "Yeah! Bedankt voor je registratie",
          "label-registrationCompletedMessage": "Wij hebben jouw gegevens in goede orde ontvangen. Dus, gas op die lolly! Je kunt vanaf nu aan de slag met jouw acount bij Boerenhelpdesk.",
          "label-comment": "Comment",
          "label-email": "Email",
          "label-password": "Wachtwoord",
          "label-domain": "Domein",
          "label-empty": "Leegmaken",
          "label-login": "Inloggen",
          "label-logInOAuth": "Inloggen with OAuth",
          "label-invalidMsg": "Ongeldige invoer. Probeer het opnieuw.",
          "label-pleaseWait": "Even geduld",
          "label-error": "Error",
          "label-invalidLoginData": "De combinatie van e-mailadres en wachtwoord is niet geldig.",
          "label-selectLocale": "Taalinstelling",
          "label-menu": "Menu",
          "label-forgotPassword": "Wachtwoord vergeten",
          "label-ok": "Ok",
          "label-cancel": "Annuleren",
          "label-usernameOrEmail": "E-mailadres",
          "label-send": "Verzenden",
          "label-backToLogin": "Terug naar login",
          "label-passwordRecoverInstruction": "Weet je het wachtwoord niet meer? Vul hieronder je e-mailadres in. We sturen dan binnen enkele minuten een e-mail waarmee een nieuw wachtwoord kan worden aangemaakt.",
          "label-passwordMismatch": "Het wachtwoord komt niet overeen.",
          "label-invalidPattern": "Het wachtwoord moet bestaan uit minimaal 8 karakters met hoofdletters, kleine letters en cijfers.",
          "label-passwordRestEmailSent": "Bedankt, je ontvangt binnen enkele minuten van ons een e-mail om een nieuw wachtwoord in te stellen.",
          "label-passwordChangedSuccessfully": "Uw wachtwoord is aangepast, ga terug naar het inlog scherm.",
          "label-passwordResetLinkExpired": "Wachtwoord link is vervallen.",
          "label-summary": "Summary",
          "label-support": "Support",
          "label-privacyStatement": "Ik ga akkoord met de",
          "label-privacyStatementAnd": "en met het",
          "label-termsAndConditionLink": "Algemene voorwaarden",
          "label-disclaimerLink": "disclaimer",
          "label-privacyStatementLink": "Privacybeleid",
          "label-submit": "Versturen",
          "label-changeDefaultPassword": "Wijzig wachtwoord",
          "label-changeDefaultPasswordMsg": "Stel uw wachtwoord opnieuw in",
          "label-enterPasscode": "Voer toegangscode in",
          "label-passcode": "Toegangscode",
          "label-resetPassword": "Stel wachtwoord opnieuw in",
          "label-confirmPassword": "Herhaal wachtwoord",
          "label-passwordChanged": "Uw wachtwoord is aangepast.",
          "label-invalidEmail": "Er is geen geldig e-mailadres ingevuld.",
          "label-firstName": "Voornaam",
          "label-lastName": "Achternaam",
          "Error-checkEmail": "Check je inbox en klik de link in de 'Bevestig je e-mailadres bij  Boerenhelpdesk' e-mail.",
          "label-infix": "Tussenvoegsel",
          "label-kvkNumber": "KVK nummer",
          "label-register": "Registreren",
          "label-companyName": "Bedrijfsnaam",
          "label-street": "Straat",
          "label-houseNumberAndAddition": "Huisnummer en toevoeging",
          "label-postCode": "Postcode",
          "label-residence": "Woonplaats",
          "label-phoneNumber": "Telefoonnummer",
          "label-next": "Volgende",
          "label-billingAddressSameAsBusiness": "Factuuradres hetzelfde als Vestigingsadres",
          "label-loginBoerenhelpdesk": "Inloggen bij Boerenhelpdesk",
          "label-registerBoerenhelpdesk": "Registreren bij Boerenhelpdesk",
          "label-completeRegistration": "Registratie voltooien",
          "label-createAccount": "Maak een account aan",
          "label-noAccountYet": "Nog geen account ?",
          "label-helpDeskSupport": "Ondersteuning van juridische helpdesk",
          "label-accessStandardDocuments": "Toegang tot standaard documenten",
          "label-quickScan": "Juridische bedrijfsscan",
          "label-needHelp": "Hulp nodig ?",
          "label-faq": "Veelgestelde vragen",
          "label-contactLoes": "Neem contact op met Loes",
          "label-directChat": "Direct chatten",
          "label-until": "tot",
          "label-viewAllQuestions": "Bekijk alle vragen",
          "label-viewFavoriteDocuments": "Waar kan ik mijn favoriete documenten bekijken",
          "label-findOverviewDocuments": "Waar vind ik een overzicht van mijn documenten",
          "label-editDocument": "Hoe kan ik een document bewerken",
          "label-advantageOfAccount": "De voordelen van een eigen account",
          "label-prepareLegalDocumentYourself": "Makkelijk zelf juridische documenten opstellen.",
          "label-businessAddress": "Vestigingsadres",
          "label-accessStandardDocument": "Toegang tot standaarddocumenten",
          "label-discountAtGoortsCoppens": "Kortingen bij Goorts + Coppens",
          "label-availableTodayFrom": "Vandaag bereikbaar van",
          "label-availableTodayFromTime": "09:00 tot 18:00",
          "label-billingAddress": "Factuuradres",
          "label-registerForNewsPaper": "Ja schrijf mij in voor de nieuwsbrief van Boerenhelpdesk.nl",
          "label-subscription": "Abonnement",
          "label-perYear": "Jaar",
          "label-perMonth": "Maand",
          "label-per3year": "Drie jaar",
          "label-perMonthly": "Maandelijks",
          "label-perYearly": "Jaarlijks",
          "label-per3Yearly": "Drie jaarlijks",
          "label-subscriptionFrequency": "Betalingsfrequentie",
          "label-back": "Terug",
          "label-save": "Opslaan",
          "label-return": "Terugkeren",
          "label-saveReturn": "Opslaan & terugkeren",
          "label-userAgreement": "Gebruikersovereekomst",
          "label-lastVersion": "Laatste versie",
          "label-continuePreviousVersion": "Verdergaan in vorige versie",
          "label-registerAndEnjoyBenefits": "Registreer en profiteer van unieke voordelen, zoals",
          "label-invalidPhoneNumber": "Ongeldig telefoonnummer",
          "label-invalidPostCode": "Ongeldig postcode",
          "label-2FAVerificationEmailSent": "Check je email voor de twee-factor authenticatie link.",
          "label-step": "Stap",
          "MenuItem-StandardDocument": "Standard documenteen",
          "MenuItem-Quickscan": "Quickscan",
          "MenuItem-DocumentGenerator": "Document generator",
          "label-feature": "Onderdeel",
          "label-functionality": "Functionaliteit",
          "label-delivery": "Levering",
          "MenuItem-HelpDesk": "Helpdesk",
          "label-yes": "Ja",
          "label-no": "Nee",
          "label-subscriptionLevel1": "Basis",
          "label-subscriptionLevel2": "Standaard",
          "label-subscriptionLevel3": "Compleet",
          "label-setupAuthenticatorApp": "Installeer authenticator app",
          "label-resetPasswordSuccess": "Uw wachtwoord is aangepast, ga terug naar het inlog scherm.",
          "label-recordSaved": "Record opgeslagen",
          "label-subscriptionList": "Abonnementen",
          "label-subscriptionDuration": "Abonnementsduur",
          "label-successfullyEmailSend": "De e-mail om je adres te bevestigen is verstuurd",
          "label-noFilters": "Geen filters",
          "label-invalidURL": "Er is geen geldige url ingevoerd.",
          "label-sendNewLink": "Stuur nieuwe link",
          "label-gender": "Geslacht",
          "label-verifyYourEmailAddress": "Verifieer je e-mail adres",
          "label-male": "Man",
          "label-female": "Vrouw",
          "label-initials": "Initialen",
          "label-birthDate": "Geboortedatum",
          "label-locale": "Taal",
          "label-mobile": "Mobiel",
          "label-position": "Functie",
          "label-department": "Afdeling",
          "label-description": "Omschrijving",
          "label-enable2FA": "Zet 2-factor authenticatie aan",
          "label-downloadAuthenticatorApp": "Download the Authenticator app voor jouw toestel via onderstaande link.",
          "label-authenticatorAppSetup": "Scan de QR code hieronder met de Authenticator app op je toestel en vul de 6 cijferige code hier in.",
          "label-isHidden": "Hidden",
          "label-knowledgeCenterComigSoon": "Het helpcentrum gaat alle informatie bevatten die jij nodig hebt voor een goede (juridische) beslissing. We zijn bezig deze tool te bouwen en laten je natuurlijk weten zodra er nog meer kennis in ons platform zit!",
          "label-notLinkedToSSO": "User isn't linked with SSO",
          "label-alwaysUpToDateLatestDevelopments": "Altijd op de hoogte van de nieuwste ontwikkelingen",
          "label-sharingKnoweldgeOfWorld": "Kennisdeling in de breedste zin van het woord van juridisch tot fiscaal tot vergunningen",
          "label-relevantCaseLaw": "Relevante rechtspraken"


        };
        localStorage.translations = JSON.stringify(this.translationData)
        this.translate.setTranslation(localStorage.locale, this.translationData, false);
        this.translate.use(localStorage.locale);
      } else {
        this.translationData = {
          "label-userName": "Username",
          "label-name": "Name",
          "label-chat": "Chat",
          "label-comment": "Comment",
          "label-thanksForRegistration": "Yeah, thanks for registering.",
          "label-registrationCompletedMessage": "We have received your info, so full speed ahead. You can now start using your Boerenhelpdesk account.",
          "label-email": "Email",
          "label-knowledgeCenter": "Knowledge base",
          "label-password": "Password",
          "label-domain": "Domain",
          "label-login": "Login",
          "label-empty": "Empty",
          "label-logInOAuth": "Login with OAuth",
          "label-invalidMsg": "Field required",
          "label-pleaseWait": "Please wait",
          "label-error": "Error",
          "label-invalidLoginData": "Combination of email and password is not correct.",
          "label-selectLocale": "Language setting",
          "label-menu": "Menu",
          "label-forgotPassword": "Forgot password",
          "label-ok": "Ok",
          "label-cancel": "Cancel",
          "label-usernameOrEmail": "E-mailaddress",
          "label-send": "Send",
          "label-successfullyEmailSend": "The verification e-mail has been sent",
          "Error-checkEmail": "Check your inbox and click the link in the 'Confirm your e-mail address at Boerenhelpdesk' e-mail.",
          "label-passwordRecoverInstruction": "Can't remember the password? Enter your email address below. We will send an e-mail within a few minutes with which a new password can be created.",
          "label-passwordMismatch": "Password doesn't match",
          "label-invalidPattern": "The password must be 8 characters and contain capitals, lower case and numeric values.",
          "label-passwordRestEmailSent": "Thank you, you will receive an email from us within a few minutes to set a new password.",
          "label-resetPassword": "Reset password",
          "label-confirmPassword": "Confirm password",
          "label-passwordChanged": "Password has been changed successful.",
          "label-passwordChangedSuccessfully": "Your password is changed successfully. Return to login page to continue.",
          "label-passwordResetLinkExpired": "Password link expired",
          "label-summary": "DGD 2.0 summary",
          "label-support": "Support",
          "label-privacyStatement": "I agree to the",
          "label-privacyStatementAnd": "and the",
          "label-termsAndConditionLink": "Terms and Conditions",
          "label-disclaimerLink": "disclaimer",
          "label-privacyStatementLink": "Privacy Policy",
          "label-submit": "Submit",
          "label-changeDefaultPassword": "Change password",
          "label-changeDefaultPasswordMsg": "Reset your password",
          "label-enterPasscode": "Enter passcode",
          "label-passcode": "Passcode",
          "label-invalidEmail": "Invalid email address",
          "label-invalidPhoneNumber": "Invalid phone number",
          "label-invalidPostCode": "Invalid postal code",
          "label-2FAVerificationEmailSent": "Check your email for the two-factor authentication link.",
          "label-step": "Step",
          "label-firstName": "firstname",
          "label-lastName": "Lastname",
          "label-infix": "Middle name",
          "label-kvkNumber": "KVK number",
          "label-register": "Register",
          "label-companyName": "Company name",
          "label-street": "Street",
          "label-houseNumberAndAddition": "House number and addition",
          "label-postCode": "Postcode",
          "label-residence": "Residence",
          "label-phoneNumber": "Phone number",
          "label-next": "Next",
          "label-billingAddressSameAsBusiness": "Billing address same as business.",
          "label-loginBoerenhelpdesk": "Login with Boerenhelpdesk",
          "label-registerBoerenhelpdesk": "Register with Boerenhelpdesk",
          "label-completeRegistration": "Complete registration",
          "label-createAccount": "Create an account",
          "label-noAccountYet": "No account yet ?",
          "label-helpDeskSupport": "Legal help desk support",
          "label-accessStandardDocuments": "Access to standard documents",
          "label-quickScan": "Quickscan",
          "label-verifyYourEmailAddress": "Verify your e-mail address",
          "label-needHelp": "Need help ?",
          "label-faq": "Frequently asked question",
          "label-contactLoes": "Please contact Loes",
          "label-directChat": "Direct chat",
          "label-until": "Until",
          "label-viewAllQuestions": "View all questions",
          "label-viewFavoriteDocuments": "Waar kan ik mijn favoriete documenten bekijken",
          "label-findOverviewDocuments": "Waar vind ik een overzicht van mijn documenten",
          "label-editDocument": "Edit document",
          "label-advantageOfAccount": "Advantage of your account",
          "label-prepareLegalDocumentYourself": "Easily prepare legal documents yourself",
          "label-businessAddress": "Business address",
          "label-accessStandardDocument": "Access to standard document",
          "label-discountAtGoortsCoppens": "Discount at Goorts + Coppens",
          "label-availableTodayFrom": "Available from",
          "label-availableTodayFromTime": "09:00 to 18:00",
          "label-billingAddress": "Billing Address",
          "label-registerForNewsPaper": "Register for newspaper of Boerenhelpdesk.nl",
          "label-subscription": "Subscription",
          "label-perYear": "Year",
          "label-perMonth": "Month",
          "label-per3year": "3 year",
          "label-perMonthly": "Monthly",
          "label-perYearly": "Yearly",
          "label-per3Yearly": "Triennial",
          "label-subscriptionFrequency": "Subscription frequency",
          "label-back": "Back",
          "label-save": "Save",
          "label-return": "Return",
          "label-saveReturn": "Save & return",
          "label-userAgreement": "User agreement",
          "label-lastVersion": "Last version",
          "label-continuePreviousVersion": "Continue in previous version",
          "label-registerAndEnjoyBenefits": "Register and enjoy unique benefits, such as",
          "MenuItem-StandardDocument": "Standard document",
          "MenuItem-Quickscan": "Quickscan",
          "MenuItem-DocumentGenerator": "Document generator",
          "MenuItem-HelpDesk": "Helpdesk",
          "label-feature": "Feature",
          "label-functionality": "Functionality",
          "label-delivery": "Delivery",
          "label-yes": "Yes",
          "label-no": "No",
          "label-subscriptionDuration": "Subscription duration",
          "label-subscriptionLevel1": "Basic",
          "label-subscriptionLevel2": "Standard",
          "label-subscriptionLevel3": "Complete",
          "label-setupAuthenticatorApp": "Setup authenticator application",
          "label-resetPasswordSuccess": "Your password has been successfully changed. Please go back to the login screen.",
          "label-recordSaved": "Record saved",
          "label-subscriptionList": "Subscription list",
          "label-noFilters": "No filters",
          "label-invalidURL": "No valid url has been entered.",
          "label-gender": "Gender",
          "label-male": "Male",
          "label-female": "Female",
          "label-initials": "Initials",
          "label-sendNewLink": "Send new link",
          "label-birthDate": "Birthdate",
          "label-locale": "Locale",
          "label-mobile": "Mobile",
          "label-position": "Position",
          "label-department": "Department",
          "label-description": "Description",
          "label-enable2FA": "Enable 2 factor authentication",
          "label-downloadAuthenticatorApp": "Download the Authenticator app for your device via the link below.",
          "label-authenticatorAppSetup": "Scan the QR code below with the Authenticator app on your device and enter the 6-digit code here.",
          "label-isHidden": "Hidden",
          "label-knowledgeCenterComigSoon": "The help center will contain all the information you need for a good (legal) decision. We are busy building this tool and will of course let you know as soon as there is more knowledge in our platform!",
          "label-notLinkedToSSO": "User isn't linked with SSO",
          "label-alwaysUpToDateLatestDevelopments": "Always up to date with the latest developments",
          "label-sharingKnoweldgeOfWorld": "Sharing knowledge in the broadest sense of the word, from legal to fiscal to permits",
          "label-relevantCaseLaw": "Relevant case law"

        };
        localStorage.translations = JSON.stringify(this.translationData)
        this.translate.setTranslation(localStorage.locale, this.translationData, false);
        this.translate.use(localStorage.locale);
      };
    } else {
      if (!localStorage.locale || localStorage.locale == "null" || localStorage.locale == "undefined") localStorage.locale = 1043;
      this.translationData = JSON.parse(localStorage.translations);
      this.translate.setTranslation(localStorage.locale, this.translationData, false);
      this.translate.use(localStorage.locale);
    }
  }

  // Get translations from API
  // if error, use the translation from locale storage. If not present in localstorage use translationData
  async getTranslation(locale) {
    return this._apiService.getTranslations('', 'systemtranslationcode', 'ASC', 1, 0)
      .then(
        (data) => {
          if (data.body.length > 0) {
            data.body.forEach((translation, index) => {
              this.translationData[translation.SystemTranslationCode] = translation.Translations[0].Text;
            });
            localStorage.translations = JSON.stringify(this.translationData);
            this.translate.setTranslation(locale, this.translationData, false);
            this.translate.use(locale);
          } else {
          }
        }
      ).catch(
        (err) => {
          if (localStorage.translations) {
            let obj = JSON.parse(localStorage.translations);
            for (let index = 0; index < obj.length; index++) {
              this.translationData[obj[index].SystemTranslationCode] = obj[index].Text;
            }
            this.translate.setTranslation(locale, this.translationData, false);
            this.translate.use(locale);
          } else {
            localStorage.translations = JSON.stringify(this.translationData);
            this.translate.setTranslation(locale, this.translationData, false);
            this.translate.use(locale);
            this.router.navigate(['/login'], { replaceUrl: true });
          }
        }
      )
  }

}
