import { Component, ViewChild } from '@angular/core';
import { PopoverController, AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

import { CommonService } from '../../../services/common.service';
import { ApiService } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';

import { TranslationService } from 'src/app/services/translation.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MatSelect } from '@angular/material/select';
import { NgForm } from '@angular/forms';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profile-popover.component.html',
  styleUrls: ['./profile-popover.component.scss'],
})
export class ProfilePopoverComponent {


  @ViewChild('domainChangeComponent', { static: false }) domainChangeComponent: IonicSelectableComponent;
  @ViewChild('domainSelect', { static: false }) domainSelect: MatSelect;
  @ViewChild('f', { read: NgForm }) form: any;


  readonly hideFieldBoeren: boolean = true;
  domains: any = [];
  show: boolean = false;
  submitted: boolean = false;
  domainPopOver: any;
  linkedToMultipleDomain: boolean = false
  isCustomerAdmin: boolean = false
  isSSO: boolean = false;

  constructor(public popoverCtrl: PopoverController, public modalController: ModalController, public ngxSmartModalService: NgxSmartModalService, private router: Router, private _authService: AuthService, private _apiService: ApiService, private alertController: AlertController, public _commonService: CommonService, private _translationService: TranslationService) { }

  ngOnInit(): void {
    this.getDomains();
    this._commonService.openFromPopover = false;
    if (localStorage.isSSO && localStorage.isSSO == 'true') {
      this.isSSO = true;
    }
  }


  ngOnDestroy(): void {
  }

  async dismissPopover() {
    await this.popoverCtrl.dismiss();
  }

  logout() {
    this._authService.logout();
  }

  selectLocale() {
    this._commonService.openLocaleModel();
  }

  openChangeDomainModal() {
    this.domainChangeComponent.open();
  }

  routeToLicenseDetail() {
    this.router.navigate(['maintenance/domains/' + localStorage.domainId])
  }



  async getDomains() {
    try {
      if (localStorage.domains && localStorage.domains != 'null' && JSON.parse(localStorage.domains).length > 0) {
        this.domains = JSON.parse(localStorage.domains);
      } else {
        let domainsData = await this._apiService.getDomains('', '', '', 1, 0, false);
        this.domains = domainsData.body;
        localStorage.domains = JSON.stringify(domainsData.body);
      }
    } catch (error) {
      this._commonService.log(error, this._commonService.ERROR);
    }
  }



  selectChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    this._commonService.log(event.value, this._commonService.LOG);
  }

  changeDomain() {
    this.domainChangeComponent.confirm();
    this.domainChangeComponent.close();
    if (this._commonService.domainName.toLowerCase() != this.domainChangeComponent.value.Name.toLowerCase()) {
      this._commonService.presentLoading();
      let params = {
        LocaleCode: ((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043),
        DomainId: this.domainChangeComponent.value.DomainId
      }
      this._authService.doDomainLogin(params, 'Login/Domain', localStorage.authenticationKey).subscribe(
        async (res) => {

          let authenticationKey = localStorage.authenticationKey;
          let menuItems = localStorage.menuItems;
          let domains = localStorage.domains;
          this._commonService.clearData();
          localStorage.authenticationKey = authenticationKey;
          localStorage.menuItems = menuItems;
          localStorage.domains = domains;

          let currentTime = new Date();
          localStorage.sessionStartTime = currentTime.getHours();

          localStorage.setItem('userId', res.UserId);
          localStorage.setItem('userName', res.UserName);
          localStorage.setItem('firstName', res.FirstName);
          localStorage.setItem('domainLocales', JSON.stringify(res.Locales));
          localStorage.setItem('menuItems', JSON.stringify(res.MenuItems));
          localStorage.setItem('domainName', res.DomainName);
          localStorage.setItem('authenticationKey', res.AuthenticationKey);
          localStorage.setItem('domainId', res.DomainId);
          localStorage.setItem('roleCode', res.RoleCode);
          localStorage.setItem('linkedToMultipleDomains', res.LinkedToMultipleDomains);
          localStorage.setItem('MaxAllowedCompanyPerDomain', res.MaxAllowedCompanyPerDomain);
          localStorage.setItem('helpImageChangeInterval', res.HelpImageChangeInterval);
          localStorage.setItem('notificationsUnreadCount', res.NotificationsUnreadCount ? res.NotificationsUnreadCount : 0);
          localStorage.setItem('Datecreated', res.Datecreated ? res.Datecreated : '');
          localStorage.setItem('FirstLogin', res.FirstLogin ? res.FirstLogin : 'false');
          localStorage.setItem('DomainSubscriptionContactNumber', res.DomainSubscriptionContactNumber ? res.DomainSubscriptionContactNumber : '');
          localStorage.setItem('TeamLeaderGuid', res.TeamLeaderGuid);

          this._commonService.phoneNumber = res.DomainSubscriptionContactNumber ? res.DomainSubscriptionContactNumber : ''
          if (res.Logo) {
            this._commonService.logo = 'data:image/png;base64,' + res.Logo;
            localStorage.setItem('Logo', 'data:image/png;base64,' + res.Logo);
          } else {
            this._commonService.logo = null;
            localStorage.removeItem('Logo');
          }

          if (res.CustomerLogo) {
            this._commonService.customerLogo = 'data:image/png;base64,' + res.CustomerLogo;
            localStorage.setItem('customerLogo', 'data:image/png;base64,' + res.CustomerLogo);
          } else {
            this._commonService.customerLogo = null;
            localStorage.removeItem('customerLogo');
          }

          this._commonService.linkedToMultipleDomains = res.LinkedToMultipleDomains
          this._commonService.notificationsUnreadCount = res.NotificationsUnreadCount ? res.NotificationsUnreadCount : 0

          this._commonService.linkedToMultipleDomains = res.LinkedToMultipleDomains
          if (res.RoleCode == 'UserRole_CustomerAdmin') {
            this._commonService.isCustomerAdmin = true
          }
          if (res.RoleCode == 'UserRole_CustomerUser') {
            this._commonService.isCustomerUser = true
          }
          this._commonService.formatMenuItems()

          localStorage.setItem('userRoles', res.UserRoles ? JSON.stringify(res.UserRoles) : null);

          this._translationService.getTranslation(localStorage.locale);


          this._commonService.domainLocales = res.Locales;


          if (res.Locales.length == 1) {
            this._commonService.locale = res.Locales[0].LocaleId;
            localStorage.locale = res.Locales[0].LocaleId;
          }
          localStorage.locale = res.DefaultLocale
          this._commonService.locale = res.DefaultLocale

          this._commonService.menuItems = res.MenuItems;
          this._commonService.domainName = res.DomainName;

          this._commonService.domain = {
            DomainId: res.DomainId,
            Name: res.DomainName
          }

          if (localStorage.currentImage) {
            localStorage.removeItem('currentImage')
          }

          if (localStorage.helpDetail) {
            localStorage.removeItem('helpDetail')
          }

          this._commonService.dismiss();


          if (this._commonService.redirectURL) {
            let url = this._commonService.redirectURL.split('/');
            this._commonService.redirectURL = null;
            this.router.navigate(['documentlibrary/' + url[2] + '/' + url[3] + '/' + url[4]]);
          }

          else if (res.RoleCode == 'UserRole_Administrator') {
            this.router.navigate(['/maintenance']);
          }
          else if (this.router.url === '/dashboard') {
            this._commonService.onDomainChange.emit("domainChanged");
          }
          else {
            this.router.navigate(['/dashboard'], { replaceUrl: true });
          }

          this._commonService.getFAQS()

          this._commonService.isLoggedIn = true;

          this._commonService.getNotificationCount();



          localStorage.domains = res.LinkedDomains ? JSON.stringify(res.LinkedDomains) : null;
          // try{
          //   let moduleList = await this._apiService.getModules('', '', '', 1, 0);
          //   localStorage.moduleList = JSON.stringify(moduleList.body);

          // }catch(error){
          //     localStorage.sessionStartTime = "";
          //     this._commonService.dismiss();
          //     this._commonService.log(error, this._commonService.ERROR);
          // }

          try {
            let settingList = await this._apiService.getSettings('', '', '', 1, 0);
            settingList.body.forEach((value, index) => {
              if (value.SettingName == "Force2FactorAuthentication") {
                localStorage.Force2FactorAuthentication = value.SettingValue;
              }

            })
          } catch (error) {
            this._commonService.dismiss();
            this._commonService.log(error, this._commonService.ERROR);
          }
          this._commonService.dismiss();

        },
        (err) => {
          this._commonService.log(err, this._commonService.ERROR);
          this._commonService.dismiss();
        }
      );
    }
  }

  onSelect(event) {
    if (!event.isSelected) {
      this.domainChangeComponent._selectedItems.push(event.item);
    }
  }

  openUserInviteModal() {
    this._commonService.openFromPopover = true
    this.ngxSmartModalService.getModal('userInviteModal').open()
  }

}